import importAll from "./import_all"

importAll(require.context("../src/helpers", true, /parseLinks\.js$/))
importAll(require.context("../src/helpers", true, /froalaPasteLinksIssues\.js$/))
importAll(require.context("./services", true, /\.coffee$/))
importAll(require.context("./components", true, /\.coffee$/))
importAll(require.context("./components", true, /\.js$/))
importAll(require.context("./helpers", true, /\.coffee$/))
importAll(require.context("./models", true, /\.coffee$/))
importAll(require.context("./page_handlers", true, /\.coffee$/))
