import React from "react"
import { func, number } from "prop-types"
import { Modal } from "react-bootstrap"

import UploadModalContent from "src/components/FilesUpload/ModalContent"
import useUpload from "src/components/FilesUpload/useUpload"
import * as supportingMaterialsApi from "src/api/supportingMaterials"

const UploadModal = ({ close, vaultItemId, addDocuments }) => {
  const uploadDocument = (document) =>
    supportingMaterialsApi.uploadDocument({ file: document.file, vaultItemId })

  const { selectedFiles, setSelectedFiles, uploadDocuments, isUploading } = useUpload({
    uploadDocument,
    addDocuments,
    close
  })

  return (
    <Modal dialogClassName="modal-lg" onHide={close} show>
      <Modal.Header closeButton className="align-items-center">
        <Modal.Title as="h6">Upload Documents</Modal.Title>
      </Modal.Header>

      <UploadModalContent
        selectedFiles={selectedFiles}
        close={close}
        uploadFiles={uploadDocuments}
        isUploading={isUploading}
        setSelectedFiles={setSelectedFiles}
        multiple
        allowedFiles="documents"
      />
    </Modal>
  )
}

UploadModal.propTypes = {
  close: func.isRequired,
  vaultItemId: number.isRequired,
  addDocuments: func.isRequired
}

export default UploadModal
