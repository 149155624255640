import { FocusableItemTypes } from "src/features/UniversalAi/constants"

const generateChannelNames = (baseKey, count) =>
  Array.from(Array(count).keys(), (i) => `${baseKey}:${i}`)

export const CHANNELS = {
  meetingPacks: "meeting-packs",
  meetingPacksTranscript: "meeting-packs:transcript",
  agendaItems: "meeting-packs:agenda-items",
  messages: "messages",
  chats: {
    updates: "chats:updates"
  },
  aiAnswer: {
    ...FocusableItemTypes.reduce(
      (acc, itemType) => ({
        ...acc,
        [itemType]: generateChannelNames(`${itemType}:ai-answer`, 10)
      }),
      {}
    ),

    froala: generateChannelNames("froala:ai-answer", 10),
    askQuestion: generateChannelNames("ask-ai-question", 10),
    draftMinutes: generateChannelNames("meeting_packs:ai-minutes", 10),
    externalContentAnswer: generateChannelNames("external-content-answer", 10)
  },
  airtable: {
    authorization: "airtable:authorization"
  },
  discussions: {
    updates: "discussions:updates"
  },
  users: {
    onlineStatus: "users:online-status",
    starredItemsStatus: "users:starred-items-status"
  },
  groups: {
    discussions: "groups:discussions",
    membership: "groups:membership",
    folders: "groups:folders",
    apps: "groups:apps"
  },
  collaborativeDocuments: "collaborative-documents",
  signedDocuments: "signed-documents",
  search: {
    results: generateChannelNames("search:results", 10)
  },
  accounts: {
    bulkAddUserToMeetingPacks: "accounts:bulk-add-user-to-meeting-packs"
  },
  supportingMaterials: "supporting-materials"
}

export const MESSAGE_NAMES = {
  messageCreate: "message-create",
  messageUpdate: "message-update",
  messageDestroy: "message-destroy"
}
