import { styled } from "@linaria/react"
import { Image } from "react-bootstrap"

import { FONT_SIZES } from "src/styles/sizes"

import { BORDER_COLOR } from "../../constants"

const TEXT_COLOR = "#181023"

export const DocumentCardContainer = styled.div`
  border-radius: 8px;
  border: 1px solid ${BORDER_COLOR};
  padding: 15px 20px;
  margin-bottom: 10px;
`

export const DocumentHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
`

export const DocumentFields = styled.div`
  display: flex;
  justify-content: space-between;
`

export const DocumentField = styled.div``

export const DocumentFieldTitle = styled.div`
  color: #46404f;
  font-size: 12px;
  font-weight: 500;
  padding-bottom: 5px;
`

export const DocumentFieldValue = styled.div`
  color: ${TEXT_COLOR};
  font-size: 14px;
  font-weight: 300;
`

export const Actions = styled.div`
  color: ${TEXT_COLOR};

  .dropdown-menu {
    width: 210px;
  }

  .dropdown-toggle {
    font-size: ${FONT_SIZES.medium};
    cursor: pointer;
  }
`

export const AlertIcon = styled(Image)`
  margin-right: 5px;
  width: 16px;
  height: 16px;
`

export const AlertContainer = styled.div`
  font-size: 12px;
  font-weight: 300;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`
