import { Card } from "react-bootstrap"
import { styled } from "@linaria/react"

import LinkButton from "src/styles/components/Button/Link"

import { BORDER_COLOR } from "../constants"

export const ItemCardContainer = styled(Card)`
  border-radius: 8px;
  border: 1px solid ${BORDER_COLOR};
  box-shadow: none;
  padding: 0;
`

export const DocumentsList = styled.div`
  padding: 15px 40px;
`

export const AddDocumentButton = styled(LinkButton)`
  color: #9647d7;
  font-size: 14px;

  &:hover,
  &:focus {
    color: #9647d7;
  }
`
