import React from "react"
import { shape, number } from "prop-types"

import ItemsList from "./ItemsList"
import { Container } from "./styles"

const SupportingMaterialsVault = ({ vault }) => {
  return (
    <div className="fluid-container">
      <div className="subheader-nav subheader-nav-grid groups-list-nav">
        <div className="subheader-title">
          <h5>Supporting Materials Vault</h5>
        </div>
      </div>

      <Container>
        <ItemsList vaultId={vault.id} />
      </Container>
    </div>
  )
}

SupportingMaterialsVault.propTypes = {
  vault: shape({ id: number.isRequired })
}

export default SupportingMaterialsVault
