import React, { useState } from "react"
import { Accordion } from "react-bootstrap"
import { shape, number, string, bool, arrayOf } from "prop-types"

import useIsOpened from "src/hooks/useIsOpened"

import UploadModal from "../UploadModal"

import Header from "./Header"
import { AddDocumentButton, DocumentsList, ItemCardContainer } from "./styles"
import DocumentCard from "./DocumentCard"
import LoadingDocument from "./LoadingDocument"

const ItemCard = ({ vaultItem, index }) => {
  const {
    isOpened: isUploadModalOpened,
    open: openUploadModal,
    close: closeUploadModal
  } = useIsOpened()
  const [loadingDocument, setLoadingDocument] = useState()

  return (
    <>
      <ItemCardContainer key={vaultItem.id}>
        <Header
          itemName={vaultItem.name}
          documentsCount={vaultItem.vaultDocuments.length}
          stale={vaultItem.vaultDocuments.some((document) => document.stale)}
          eventKey={`${index}`}
        />

        <Accordion.Collapse eventKey="0">
          <DocumentsList>
            {vaultItem.vaultDocuments.map((vaultDocument) => (
              <DocumentCard
                key={vaultDocument.id}
                document={vaultDocument}
                staleNotificationText={vaultItem.staleNotificationText}
              />
            ))}

            {loadingDocument && <LoadingDocument filename={loadingDocument.name} />}

            <AddDocumentButton onClick={openUploadModal}>
              <strong>+</strong> Add document
            </AddDocumentButton>
          </DocumentsList>
        </Accordion.Collapse>
      </ItemCardContainer>

      {isUploadModalOpened && (
        <UploadModal
          close={closeUploadModal}
          vaultItemId={vaultItem.id}
          addDocuments={(documents) => setLoadingDocument(documents[0])}
        />
      )}
    </>
  )
}

ItemCard.propTypes = {
  vaultItem: shape({
    id: number.isRequired,
    name: string.isRequired,
    vaultDocuments: arrayOf(
      shape({
        id: number.isRequired,
        filename: string.isRequired,
        pspdfkitId: string.isRequired,
        stale: bool.isRequired
      })
    )
  }).isRequired,
  index: number.isRequired
}

export default ItemCard
