// eslint-disable-next-line import/no-extraneous-dependencies
import { Passage } from "@passageidentity/passage-js"

import { MyTokenStore } from "./MyTokenStore"

const appId = () => window.passageAppId

export const passkeyLogin = async (email) => {
  const tokenStore = new MyTokenStore()
  const passage = new Passage(appId(), { tokenStore })

  return passage.passkey.login(email) // it calls tokenStore.setTokens under the hood
}

export const addPasskeyDevice = (authToken) => {
  const tokenStore = new MyTokenStore()
  const passage = new Passage(appId(), { tokenStore })

  return tokenStore.setTokens({ authToken }).then(() => passage.currentUser.addPasskey())
}
