// eslint-disable-next-line import/no-extraneous-dependencies
import { TokenStore } from "@passageidentity/passage-js"

import apiClient from "src/app/apiClient"

const TOKEN_KEY = "passkey_token"

export class MyTokenStore extends TokenStore {
  getAuthToken() {
    const passkeyToken = localStorage.getItem(TOKEN_KEY)
    return Promise.resolve(passkeyToken)
  }

  setTokens(response) {
    const { authToken, redirectUrl } = response

    return apiClient
      .post(
        "/passkey_login/token",
        {},
        {
          headers: {
            Authorization: `Bearer ${authToken}`
          }
        }
      )
      .then(() => {
        localStorage.setItem(TOKEN_KEY, authToken)
        if (redirectUrl) window.location.href = redirectUrl
      })
  }
}
