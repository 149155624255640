import React from "react"
import { styled } from "@linaria/react"
import { oneOf, elementType, string } from "prop-types"

import { composeVariationsClasses } from "src/helpers/linaria"
import { FONT_SIZES } from "src/styles/sizes"
import { BASE_COLORS } from "src/styles/colors"

const WarningText = ({
  component: Component = "span",
  text,
  variant,
  className,
  ...rest
}) => {
  if (!text?.length) {
    return null
  }

  const classes = composeVariationsClasses(className, { variant })

  return (
    <Component className={classes} {...rest}>
      {text}
    </Component>
  )
}

WarningText.propTypes = {
  component: elementType,
  text: string,
  variant: oneOf(["", "success", "danger"]),
  className: string
}

export default styled(WarningText)`
  font-size: ${FONT_SIZES.small};

  &--variant-success {
    color: ${BASE_COLORS.green};
  }

  &--variant-danger {
    color: ${BASE_COLORS.red};
  }
`
