import React, { useContext } from "react"
import { Accordion, AccordionContext } from "react-bootstrap"
import { string, number, bool } from "prop-types"

import Icon from "src/styles/components/Icon"
import alertIcon from "images/alert-circle.svg"

import { CardTitle, CardTitleContainer, Badge, AlertIcon } from "./styles"

const Header = ({ itemName, documentsCount, eventKey, stale }) => {
  const currentEventKey = useContext(AccordionContext)
  const isCurrentEventKey = currentEventKey === eventKey

  return (
    <Accordion.Toggle as="div" eventKey={eventKey}>
      <CardTitleContainer>
        <div className="d-flex align-items-center">
          <Icon
            type="dropdown-arrow"
            className={`d-block ${isCurrentEventKey && "icon-rotated"}`}
          />
          <CardTitle>{itemName}</CardTitle>
        </div>
        <span>
          <Badge>{documentsCount}</Badge>documents
          {stale && <AlertIcon height={16} src={alertIcon} />}
        </span>
      </CardTitleContainer>
    </Accordion.Toggle>
  )
}

Header.propTypes = {
  itemName: string.isRequired,
  documentsCount: number.isRequired,
  eventKey: string.isRequired,
  stale: bool.isRequired
}

export default Header
