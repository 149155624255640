import "core-js/stable"
import "regenerator-runtime/runtime"
import "@hotwired/turbo-rails"
import "bootstrap"

/* eslint-disable */
import Rails from "@rails/ujs"
window.Rails = Rails
Rails.start()

import * as ActiveStorage from "activestorage"
ActiveStorage.start()

import moment from "moment-timezone"
window.moment = moment

import truncate from "truncate-html"
window.truncate = truncate

import Popper from "popper.js"
window.Popper = Popper

import Tribute from "tributejs"
window.Tribute = Tribute

import loadImage from "blueimp-load-image/js/load-image.all.min"
window.loadImage = loadImage

import iNoBounce from "inobounce"
window.iNoBounce = iNoBounce

import { Realtime } from "ably"
window.Ably = { Realtime }

import favico from "favico.js"
window.Favico = favico

import humanizeDuration from "humanize-duration"
window.humanizeDuration = humanizeDuration

import flatpickr from "flatpickr"
$.fn.flatpickr = function (config) {
  return flatpickr(this, config)
}

import SimpleLightbox from "simplelightbox"
$.fn.simpleLightbox = function (config) {
  if (!this.length) return
  return new SimpleLightbox(this, config)
}

import "jquery-ui/dist/jquery-ui"
import "jquery-inview"
import "select2"
import "password-strength-meter"
import "jq-tablesort/dist/tablesort"
import "@loadingio/loading-bar"

import "./vendors.sass"
