import React from "react"
import { string } from "prop-types"
import { Image } from "react-bootstrap"

import generatingImgUrl from "images/ai/generating.png"

import FileName from "../FileName"

import { LoadingDocumentContainer } from "./styles"

const LoadingDocument = ({ filename }) => {
  return (
    <LoadingDocumentContainer className="d-flex align-items-center justify-content-between">
      <FileName filename={filename} />
      <Image height="14" src={generatingImgUrl} alt="" />
    </LoadingDocumentContainer>
  )
}

LoadingDocument.propTypes = {
  filename: string.isRequired
}

export default LoadingDocument
