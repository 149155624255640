import React from "react"
import { func } from "prop-types"

import UploadModalContent from "src/components/FilesUpload/ModalContent"
import useUpload from "src/components/FilesUpload/useUpload"

const UploadSection = ({ close, uploadDocument, addAttachedDocuments }) => {
  const { selectedFiles, setSelectedFiles, uploadDocuments, isUploading } = useUpload({
    uploadDocument,
    addDocuments: addAttachedDocuments,
    close
  })

  return (
    <UploadModalContent
      selectedFiles={selectedFiles}
      close={close}
      uploadFiles={uploadDocuments}
      isUploading={isUploading}
      setSelectedFiles={setSelectedFiles}
      multiple
      allowedFiles="documents"
    />
  )
}

UploadSection.propTypes = {
  close: func.isRequired,
  uploadDocument: func.isRequired,
  addAttachedDocuments: func.isRequired
}

export default UploadSection
