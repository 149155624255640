import { useState } from "react"

const useUpload = ({ uploadDocument, addDocuments, close }) => {
  const [selectedFiles, setSelectedFiles] = useState([])
  const isUploading = selectedFiles.some((file) => file.isUploading)

  const setSelectedFilesWithMeta = (files) => {
    setSelectedFiles(files.map((file) => ({ file, name: file.name, isUploading: false })))
  }

  const setAllFilesAsUploading = () => {
    setSelectedFiles(selectedFiles.map((file) => ({ ...file, isUploading: true })))
  }

  const setFileAsNotUploading = (file) => {
    setSelectedFiles((actualFiles) => {
      return actualFiles.map((selectedFile) => ({
        ...selectedFile,
        isUploading:
          file.name === selectedFile.file.name ? false : selectedFile.isUploading
      }))
    })
  }

  const uploadDocuments = () => {
    setAllFilesAsUploading()

    Promise.all(
      selectedFiles.map((document) =>
        uploadDocument(document).then(({ data }) => {
          setFileAsNotUploading(document)

          return { id: data.id, name: document.name }
        })
      )
    ).then((documents) => {
      addDocuments(documents)
      close()
    })
  }

  return {
    selectedFiles,
    setSelectedFiles: setSelectedFilesWithMeta,
    uploadDocuments,
    isUploading
  }
}

export default useUpload
