import React from "react"
import classNames from "classnames"
import { bool, number, string } from "prop-types"

import { MenuItem as MenuItemContainer } from "src/features/Sidebar/styles"

const MenuItem = ({ id, badge, path, current, name }) => {
  return (
    <MenuItemContainer
      className={classNames(badge, { current })}
      data-testid="sidebar-menu-item"
      data-id={id}
    >
      <a href={path} className="menu-link">
        {name}
      </a>
    </MenuItemContainer>
  )
}

MenuItem.propTypes = {
  id: number.isRequired,
  badge: string,
  current: bool.isRequired,
  name: string.isRequired,
  path: string.isRequired
}

export default MenuItem
