import { styled } from "@linaria/react"

import { BORDER_COLOR } from "../../constants"

export const LoadingDocumentContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  border: 1px solid ${BORDER_COLOR};
  padding: 15px 20px;
  margin-bottom: 10px;
`
