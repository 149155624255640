import * as Sentry from "@sentry/browser"

import { be } from "src/helpers/document"
import { passkeyDeviceId } from "src/features/Passkey/storage"
import { passkeyLogin } from "src/features/Passkey/actions"

export class LoginFormComponent {
  constructor() {
    this.setup = this.setup.bind(this)
    this.checkCSRFToken = this.checkCSRFToken.bind(this)
    this.requestSSO = this.requestSSO.bind(this)
    this.responseHandler = this.responseHandler.bind(this)
    this.showFullForm = this.showFullForm.bind(this)
    this.overwriteEnterButton = this.overwriteEnterButton.bind(this)

    this.emailInput = be("email-input")
    this.nextButton = be("next-button")
    this.basicAuthForm = be("basic-auth-form")
  }

  setup() {
    this.checkCSRFToken()
    this.overwriteEnterButton()
    this.nextButton.click((e) => {
      e.preventDefault()
      this.requestSSO()
    })
  }

  checkCSRFToken() {
    $.ajax({
      url: "/token",
      type: "POST",
      error: (err) => {
        const wasReloaded = new URLSearchParams(window.location.search).get("r")
        if (err.status === 422 && !wasReloaded)
          window.location.href = `${window.location.pathname}?r=1`
      }
    })
  }

  requestSSO() {
    $.ajax({
      url: "/sso",
      type: "GET",
      data: { email: this.emailInput.val() }
    }).done(this.responseHandler)
  }

  responseHandler(response) {
    const email = this.emailInput.val()

    if (response.location) window.location.assign(response.location)
    else if (passkeyDeviceId(email)) {
      passkeyLogin(email).catch((e) => {
        Sentry.captureException(e)
        this.showFullForm()
      })
    } else this.showFullForm()
  }

  showFullForm() {
    this.basicAuthForm.removeClass("d-none")
    this.nextButton.hide()
  }

  overwriteEnterButton() {
    $(document).keypress((e) => {
      if (e.which !== 13) return
      if (!this.nextButton.is(":hidden")) {
        e.stopPropagation()
        this.nextButton.click()
        return false
      }
    })
  }
}

window.LoginFormComponent = LoginFormComponent
