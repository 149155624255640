import React from "react"
import { arrayOf, bool, number, oneOfType, shape, string } from "prop-types"
import { useMutation } from "@apollo/client"
import { Dropdown } from "react-bootstrap"

import Icon from "src/styles/components/Icon"
import { preventDefault } from "src/helpers/events"
import TridotIcon from "src/components/TridotIcon"
import useConfirmationModal from "src/hooks/useConfirmationModal"
import alertIcon from "images/alert-circle.svg"
import useIsOpened from "src/hooks/useIsOpened"

import FileName from "../FileName"

import {
  Actions,
  DocumentCardContainer,
  DocumentField,
  DocumentFields,
  DocumentFieldTitle,
  DocumentFieldValue,
  DocumentHeader,
  AlertIcon,
  AlertContainer
} from "./styles"
import deleteDocumentMutation from "./deleteDocument.gql"
import PreviewModal from "./PreviewModal"

const DocumentCard = ({ document, staleNotificationText }) => {
  const [deleteVaultDocument] = useMutation(deleteDocumentMutation, {
    variables: { vaultDocumentId: document.id }
  })

  const { modal: deleteDocumentModal, open: openDeleteDocumentModal } =
    useConfirmationModal({
      title: "Confirmation",
      message: "Are you sure you want to delete this document?",
      onConfirm: deleteVaultDocument
    })

  const {
    isOpened: isPreviewModalOpened,
    open: openPreviewModal,
    close: closePreviewModal
  } = useIsOpened(false)

  return (
    <DocumentCardContainer>
      {deleteDocumentModal}

      {isPreviewModalOpened && (
        <PreviewModal
          close={closePreviewModal}
          isOpened
          name={document.filename}
          pspdfkitId={document.pspdfkitId}
          jwt={document.jwt}
        />
      )}

      <DocumentHeader>
        <FileName filename={document.filename} onClick={openPreviewModal} />

        <Actions onClick={preventDefault}>
          <Dropdown>
            <Dropdown.Toggle as={TridotIcon} />

            <Dropdown.Menu alignRight>
              <Dropdown.Item onClick={openDeleteDocumentModal}>
                <Icon type="trash" className="pr-2" /> Remove document
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Actions>
      </DocumentHeader>

      {document.stale && (
        <AlertContainer>
          <AlertIcon src={alertIcon} />
          {staleNotificationText}
        </AlertContainer>
      )}

      <DocumentFields>
        {document.itemFieldValues.map((itemFieldValue) => (
          <DocumentField key={itemFieldValue.name}>
            <DocumentFieldTitle>{itemFieldValue.name}</DocumentFieldTitle>
            <DocumentFieldValue>{itemFieldValue.value}</DocumentFieldValue>
          </DocumentField>
        ))}
      </DocumentFields>
    </DocumentCardContainer>
  )
}

DocumentCard.propTypes = {
  document: shape({
    id: number.isRequired,
    filename: string.isRequired,
    pspdfkitId: string.isRequired,
    stale: bool.isRequired,
    itemFieldValues: arrayOf(
      shape({ name: string.isRequired, value: oneOfType([string, number]).isRequired })
    )
  }),
  staleNotificationText: string
}

export default DocumentCard
