import React, { useEffect, useRef } from "react"
import { string } from "prop-types"
import * as Sentry from "@sentry/browser"

import { bodyData } from "src/helpers/document"

import { DocumentViewerContainer } from "./styles"

const DocumentViewer = ({ pspdfkitId, jwt }) => {
  const containerRef = useRef(null)

  useEffect(() => {
    if (window.PSPDFKit) {
      window.PSPDFKit.load({
        licenseKey: window.PSPDFKitKey,
        serverUrl: window.pspdfkitServerUrl,
        documentId: pspdfkitId,
        toolbarItems: [],
        layers: [],
        enableAutomaticLinkExtraction: false,
        printOptions: {
          mode: window.PSPDFKit.PrintMode.EXPORT_PDF,
          quality: window.PSPDFKit.PrintQuality.HIGH
        },
        container: "#document-preview",
        editableAnnotationTypes: [],
        styleSheets: [bodyData("pspdfkitPreviewPath")],
        authPayload: { jwt },
        instant: false
      }).catch((e) => {
        Sentry.captureException(e)
      })

      return () => {
        if (document.querySelector("#document-preview"))
          window.PSPDFKit.unload("#document-preview")
      }
    }
  }, [pspdfkitId, !!window.PSPDFKit])

  return <DocumentViewerContainer id="document-preview" ref={containerRef} />
}

DocumentViewer.propTypes = {
  pspdfkitId: string.isRequired,
  jwt: string.isRequired
}

export default DocumentViewer
