import React from "react"
import { string, bool } from "prop-types"
import classNames from "classnames"

const HeaderMenuItem = ({ caption, path, highlighted, iconClassName }) => {
  return (
    <li className="navigation-menu-item">
      <a
        href={path}
        className={classNames("navigation-menu-link", {
          highlighted
        })}
      >
        <span className={iconClassName} />
        <span className="menu-link-caption">{caption}</span>
      </a>
    </li>
  )
}

HeaderMenuItem.propTypes = {
  caption: string.isRequired,
  path: string.isRequired,
  highlighted: bool.isRequired,
  iconClassName: string.isRequired
}

export default HeaderMenuItem
