import { isWideScreen } from "src/helpers/browser"
import { SIDEBAR } from "src/constants/storage"

const be = (behavior) => $(`[data-behavior~=${behavior}]`)

const beById = (behavior, id) => $(`[data-behavior~=${behavior}][data-id=${id}]`)

const beByEntity = (behavior, entity, id) =>
  $(`[data-behavior~=${behavior}][data-type=${entity}][data-id=${id}]`)

const beByEntityInAccount = (behavior, entity, id, accountId) =>
  $(
    `[data-behavior~=${behavior}][data-type=${entity}][data-id=${id}][data-account-id=${accountId}]`
  )

const findByText = (nodeList, text) =>
  Array.from(nodeList).find((node) => node.innerHTML.includes(text))

const bodyData = (key) => document.querySelector("body").dataset[key]

const getCurrentMeetingPackId = () => be("app-layout").data("current-meeting-pack-id")

const THREAD_TYPE_BY_BEHAVIOR = {
  "chat-page": "ChatThread",
  "discussion-page": "DiscussionThread"
}

const getCurrentThread = () => {
  const currentMeetingPackId = getCurrentMeetingPackId()

  if (currentMeetingPackId) {
    return {
      id: currentMeetingPackId,
      type: "MeetingPackThread"
    }
  }

  const threadPage = $("[data-category=thread-page]")

  if (!threadPage.length) {
    return null
  }

  const { id, boxFolderId } = threadPage.data()

  return {
    id,
    boxFolderId,
    type: THREAD_TYPE_BY_BEHAVIOR[threadPage.data("behavior")]
  }
}

const getCurrentMainSchemeId = () => be("app-layout").data("current-main-scheme-id")
const getCurrentSupportingMaterialsVaultId = () =>
  be("app-layout").data("current-supporting-materials-vault-id")

const getCurrentGroupData = () => window.currentGroupData

const getBaseUrl = () => window.location.origin

const isSidebarVisible = () => {
  if (!isWideScreen() || !localStorage.getItem(SIDEBAR.stateKey)) {
    return true
  }

  return localStorage.getItem(SIDEBAR.stateKey) === SIDEBAR.shown
}

const togglePersistedSidebarState = () => {
  // toggle state only on desktop
  if (!isWideScreen()) return

  return localStorage.setItem(
    SIDEBAR.stateKey,
    isSidebarVisible() ? SIDEBAR.hidden : SIDEBAR.shown
  )
}

const toggleSidebar = () => {
  const body = $("body")
  body.addClass("with-transition")
  body.toggleClass("no-sidebar")

  $(".header .sidebar-toggle").toggleClass("show-sidebar")

  const sidebarElement = document.querySelector("[data-behavior=sidebar]")
  const rect = sidebarElement.getBoundingClientRect()
  const opened = rect.x >= 0
  const sidebarToggledEvent = new CustomEvent("sidebar-toggled", { detail: { opened } })
  window.dispatchEvent(sidebarToggledEvent)

  togglePersistedSidebarState()
}

const closeSidebar = () => {
  const body = $("body")
  body.addClass("with-transition")
  body.addClass("no-sidebar")

  $(".header .sidebar-toggle").removeClass("show-sidebar")

  togglePersistedSidebarState()
}

const trigger = (eventType, eventData) => {
  $("body").trigger($.extend({ type: eventType }, eventData))
}

const scrollDown = (findElementFunc) => {
  if (!findElementFunc) return

  const element = findElementFunc()
  if (!element) return

  element.scrollTop = element.scrollHeight
}

const mainContentWidth = () => {
  const mainContent = document.querySelector("[data-behavior=content]")
  const { width } = mainContent.getBoundingClientRect()

  return width
}

const isMainContentNarrow = () => {
  return mainContentWidth() < 988
}

export {
  be,
  beById,
  beByEntity,
  beByEntityInAccount,
  findByText,
  bodyData,
  getCurrentThread,
  getCurrentMeetingPackId,
  getCurrentMainSchemeId,
  getCurrentSupportingMaterialsVaultId,
  getCurrentGroupData,
  getBaseUrl,
  toggleSidebar,
  closeSidebar,
  mainContentWidth,
  isMainContentNarrow,
  isSidebarVisible,
  scrollDown,
  togglePersistedSidebarState,
  trigger
}
