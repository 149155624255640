import { useMutation } from "@apollo/client"

import { QueryTypes } from "../constants"

import defineQueryTypeMutation from "./defineQueryTypeMutation.gql"

const RAW_QUERY_TYPES = {
  1: QueryTypes.Keyword,
  2: QueryTypes.Query,
  3: QueryTypes.SummariseByQuery
}

const useDefineQueryType = () => {
  const [mutate] = useMutation(defineQueryTypeMutation)

  const defineQueryType = async ({ query, focusedItem }) => {
    const focusedItemInput = { id: focusedItem.id, type: focusedItem.type }
    const { data } = await mutate({ variables: { query, focusedItem: focusedItemInput } })
    return RAW_QUERY_TYPES[data?.askAiQuestionType?.questionType]
  }

  return defineQueryType
}

export default useDefineQueryType
