import * as Sentry from "@sentry/browser"

import { getCurrentUser } from "src/helpers/user"

if (process.env.RAILS_ENV === "production") {
  Sentry.init({
    dsn: process.env.SENTRY_FRONTEND_DSN,
    tunnel: "/sentry_tunnel",
    denyUrls: [
      "/froala_editor.min.js",
      "/froala-editor/",
      "intercomcdn.com/",
      "intercom.io/"
    ],
    ignoreErrors: [
      "Non-Error exception captured",
      "Non-Error promise rejection captured",
      "SentryError: HTTP Error (429)",
      "Failed to fetch"
    ],
    // eslint-disable-next-line new-cap
    integrations: [Sentry.browserTracingIntegration()],
    transport: Sentry.makeBrowserOfflineTransport(Sentry.makeFetchTransport),
    tracesSampler: (_samplingContext) => {
      if (process.env.SENTRY_PERFORMANCE_MONITOR_ENABLED === "true") {
        return 0.15 // 15% of transactions are sent to Sentry
      }
      return false
    },

    beforeSend(event) {
      const modifiedEvent = event
      try {
        // IE throws SecurityError when other browser don't
        if (window.navigator.userAgent.indexOf("Trident") > -1) {
          if (event.message && event.message.match(/SecurityError/)) {
            return null
          }
        }

        const currentUser = getCurrentUser()
        modifiedEvent.user = {
          id: currentUser.id,
          email: currentUser.email
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e)
      }

      return modifiedEvent
    }
  })
}
