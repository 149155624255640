import React, { useEffect, useRef } from "react"
import { Form, InputGroup } from "react-bootstrap"
import { useFormikContext } from "formik"
import { string } from "prop-types"
import { styled } from "@linaria/react"
import intlTelInput from "intl-tel-input"

import geoIp from "src/api/geoIp"

const PhoneInput = ({ error, className }) => {
  const phoneInputRef = useRef()

  const { handleChange, handleBlur, values, errors, touched, setFieldValue } =
    useFormikContext()

  useEffect(() => {
    if (!phoneInputRef.current) return

    const iti = intlTelInput(phoneInputRef.current, {
      initialCountry: "auto",
      geoIpLookup: (callback) => {
        geoIp()
          .then((data) => callback(data.countryCode))
          .catch(() => callback("gb"))
      },
      autoPlaceholder: "off",
      countryOrder: ["gb", "us", "fr"],
      separateDialCode: true,
      formatOnDisplay: false,
      countrySearch: false
    })

    if (values.countryCode) {
      iti.setCountry(values.countryCode)
    }

    const setCountryData = () => {
      const countryData = iti.getSelectedCountryData()

      setFieldValue("countryCode", countryData.iso2)
      setFieldValue("dialCode", countryData.dialCode)
    }

    phoneInputRef.current.addEventListener("countrychange", setCountryData)

    phoneInputRef.current.addEventListener("change", () =>
      setFieldValue("phone", phoneInputRef.current.value)
    )

    return () => {
      phoneInputRef.current?.removeEventListener("countrychange", setCountryData)
      iti?.destroy()
    }
  }, [])

  return (
    <div className={className}>
      <h5 className="mt-4 text-muted">Step 1</h5>
      <p className="text-muted">
        Enter a trusted phone number such as your mobile phone, where you can receive
        verification codes by text message.
      </p>
      <Form.Group controlId="phone">
        <Form.Label className="text-muted">Mobile number</Form.Label>
        <InputGroup>
          <Form.Control value={values.countryCode} hidden readOnly />
          <Form.Control value={values.dialCode} hidden readOnly />
          <Form.Control
            ref={phoneInputRef}
            value={values.phone}
            onChange={handleChange}
            onBlur={handleBlur}
            autoComplete="off"
            isInvalid={error || (errors.phone && touched.phone)}
          />
          <Form.Control.Feedback type="invalid">
            {error || errors.phone}
          </Form.Control.Feedback>
        </InputGroup>
      </Form.Group>
    </div>
  )
}

PhoneInput.propTypes = {
  error: string
}

export default styled(PhoneInput)`
  .iti {
    width: 100%;
  }

  .iti:has(.is-invalid) ~ .invalid-feedback {
    display: block;
  }
`
