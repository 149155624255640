import { styled } from "@linaria/react"
import { Row, Image } from "react-bootstrap"

import { BORDER_COLOR } from "../../constants"

export const CardTitleContainer = styled(Row)`
  margin: 0;
  padding: 15px;
  justify-content: space-between;
  color: #181023;
  font-size: 14px;
  font-weight: 300;
  border-bottom: 1px solid ${BORDER_COLOR};
  cursor: pointer;

  .icon-rotated {
    transform: rotate(180deg);
  }
`

export const CardTitle = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: -0.32px;
  padding-left: 7px;
`

export const Badge = styled.span`
  border-radius: 100px;
  background: ${BORDER_COLOR};
  color: #7e90a9;
  padding: 2px 5px;
  font-size: 10px;
  margin-right: 5px;
`

export const AlertIcon = styled(Image)`
  margin-left: 5px;
  width: 16px;
  height: 16px;
`
