import React from "react"
import { func, string } from "prop-types"

import FileIcon from "src/styles/components/FileIcon"
import { splitFilename } from "src/helpers/string"

import { FileName, Container } from "./styles"

const FileNameContainer = ({ filename, onClick }) => {
  const { extension } = splitFilename(filename)

  return (
    <Container onClick={onClick}>
      <FileIcon extension={extension} showExtension={false} className="extension-icon" />
      <FileName>{filename}</FileName>
    </Container>
  )
}

FileNameContainer.propTypes = {
  filename: string.isRequired,
  onClick: func
}

export default FileNameContainer
